import 'slick-carousel'
import 'magnific-popup';
import momemt from 'moment';
import "./insta";

jQuery( document ).ready(function($) {
  // mobile menu
  $('.menu-trigger').click(function(){
    $(this).toggleClass('active')
    $('.menu').toggleClass('active')
  })
  $('.menu a').click(function(){
    $('.menu, .menu-trigger').removeClass('active')
  })

  // sliders
  $('#music .wrapper').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    arrows: true,
    adaptiveHeight: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>'
  });
  $('.slider-videos').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>'
  });

  // events
  $.ajax( {
    url: 'https://rest.bandsintown.com/artists/Ben%20Fuller%20Music/events?app_id=45PRESS_BEN_FULLER_MUSIC',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert( 'Error fetching events!' );
    },
    success: data => {
    const events = $( '#events' );
    let html = '';
    if ( data.length ) {
      for ( let event of data ) {
        let region = event.venue.region?event.venue.region:event.venue.country;
        let location = event.venue.city + ', ' + region;
        if ( location === ', ' ) {location = '';}
        if ( event.venue.name.toLowerCase() === 'streaming live' ) {location = 'Online';}
        html += '<div class="event">';
          html += '<div class="event-date">' + momemt( event.datetime ).format( 'MMM D' ) + '</div>';
          html += '<div class="event-info">';
            html += '<div class="event-location">' + location + '</div>';
            html += '<div class="event-venue">' + event.venue.name + '</div>';
          html += '</div>';
          html += '<div class="event-links">';
          for ( let offer of event.offers ) {
            html += '<a href="' + offer.url + '" target="_blank" class="btn btn-white btn-big">' + offer.type + '</a>';
          }
          html += '</div>';
        html += '</div>';
      }
      events.html( html );
      } else {
        events.html( 'No upcoming events.' );
      }
    }
  });

  //Presave Popup
  $.magnificPopup.open({
    items: {
      src: '#popup',
      type: 'inline'
    }
  });
});